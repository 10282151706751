/* Insert fonts: Thank you, Raustila, Shree Devanagari 714, Playfair Display */
@font-face {
  font-family: 'Raustila';
  src: url('fonts/raustila-Regular.ttf')  format('truetype');
}

@font-face {
  font-family: 'Shree';
  src: url('fonts/Shree-Devanagari-714.ttf')  format('truetype');
}

@font-face {
  font-family: 'Playfair Display';
  src: url('fonts/PlayfairDisplay.ttf')  format('truetype');
}

@font-face {
  font-family: 'Thank You';
  src: url('fonts/Thank-you.ttf')  format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  padding: 0;
}

:root {
  --light-blue: rgb(234, 246, 255);
  --dark-blue: rgb(1, 62, 166);
  --blush: rgb(250, 229, 227);
  --green: rgb(45, 83, 15);
  --white: rgb(253, 253, 253);
}

.backdrop {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 1030;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
}

.center {
  text-align: center;
}

.dark-blue {
  color: var(--dark-blue);
}

hr.dark-blue {
  border-color: var(--dark-blue);
}

.bg--light-blue {
  background-color: var(--light-blue);
}

a:link.dark-blue,a:visited.dark-blue,a:active.dark-blue {
  color: var(--dark-blue);
}

.header-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: top;
}

.cut-image {
  object-position: center;
}

.wrapper {
  width: 100%;
  margin: auto;
  padding-top: 180px;
}

.banner {
  width: 100%;
  background-color: rgb(240, 178, 177);
  color: white;
  font-family: 'Shree';
  font-weight: 900;
  padding-top: 15px;
  z-index: 1020;
}

.banner--expanded {
  position: relative;
  height: 100vh;
}

.banner-text--x-wrapper {
  height: 30px;
}

.banner-text--x {
  padding: 0 20px;
  font-size: 2.1rem;
  float: right;
}

.banner-text--x:hover,:active {
  cursor: pointer;
}

.banner-text--expanded {
  font-family: 'Shree';
  padding: 110px 50px;
}

.banner-text--expanded p {
  font-size: 0.9rem;
}

.banner-florals--top {
  width: 150px;
  position: absolute;
  top: 0;
  left: 10px;
}

.banner-florals--bottom {
  width: 150px;
  position: absolute;
  left: calc(100vw - 180px);
  top: calc(100vh - 180px);
}

.banner-text--alert {
  font-size: 1rem;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.banner-text--arrow {
  position: relative;
  height: 15px;
  margin-left: 15px;
}

.banner-text--arrow:hover,:active {
  cursor: pointer;
}

.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 15px;
  height: 15px;
  top: 50%;
  margin: -20px 0 0 -20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
}
  
.arrow:before {
  content: '';
  width: 10px;
  height: 10px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1040;
}

.signature-text {
  font-family: 'Raustila';
  font-size: 1.7rem;
}

.nav-bar {
  font-family: 'Shree';
  background-color: white;
  box-shadow: 0px 3px 3px -3px rgb(164, 186, 202);
}

.main-nav {
  display: none;
}

.nav-bar--link-wrapper {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  margin: auto;
}

.nav-bar--monogram {
  display: none;
  width: 100px;
  margin: auto;
}

.mobile-nav--monogram {
  width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.nav-bar-item {
  display: flex;
  text-transform: uppercase;
  text-decoration: none;
}

.mobile-nav {
  position: fixed;
  display: none;
  z-index: 1040;
  top: 0;
  left: 60%;
  background: white;
  width: 40%;
  height: 100vh;
  box-shadow: -2px 0px 3px -3px rgb(164, 186, 202);
}

.open {
  display: block !important;
}

.mobile-nav-icon {
  width: 25px;
  margin: 25px;
}

.mobile-nav-icon--bar {
  width: 100%;
  text-align: left;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.mobile-nav--item {
  width: 100%;
  text-transform: uppercase;
  padding: 15px;
  font-size: 0.9rem;
}

.mobile-nav--item:hover, .mobile-nav--item:active {
  background-color: var(--light-blue);
}

.mobile-nav--item a {
  display: inline-block;
  width: 100%;
  padding: 0;
}

a {
  color: rgb(110, 156, 235);
  letter-spacing: 1px;
  padding: 10px 0;
}

.colour {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
}

.blush {
  background-color: var(--blush);
}

.navy {
  background-color: var(--dark-blue);
}

.baby-blue {
  background-color: var(--light-blue);
}

.white {
  background-color: var(--white);
}

.green {
  background-color: var(--green);
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.section {
  padding: 20px 0;
  margin: 0 auto
}

.content-wrapper {
  font-family: 'Shree';
  background-color: white;
  color: var(--dark-blue);
  text-align: center;
  width: 90%;
  border: 1px solid var(--light-blue);
  border-radius: 25px;
  margin: 20px auto;
  padding: 15px;
  font-size: 1rem;
}

.content-title {
  font-family: 'Playfair Display', sans-serif;
  font-size: 2rem;
  padding-bottom: 12px;
  letter-spacing: 0.35rem;
  text-transform: uppercase;
}

.content-title--nop {
  font-family: 'Playfair Display', sans-serif;
  letter-spacing: 0.35rem;
  text-transform: uppercase;
}

.content-subtitle {
  font-size: 0.9rem;
  margin: 0;
}

.content-text {
  font-family: 'Shree';
  color: var(--dark-blue);
}

.intro--names {
  font-size: 2rem;
  font-family: 'Raustila';
  vertical-align: middle;
}

.intro--text {
  font-size: 0.9rem;
  font-family: 'Thank You';
}

.info--title {
  font-family: 'Thank You';
  font-weight: bold;
  font-size: 2rem;
  padding: 10px 0;
}

.info--title--nop {
  font-family: 'Thank You';
  font-weight: bold;
  font-size: 2rem;
  padding-top: 10px;
}

.info--img {
  position: relative;
  width: 100px;
  transform: rotate(15deg);
  z-index: 1000;
}

.info--date {
  font-family: 'Thank You';
  font-size: 2.5rem;
  font-weight: bold;
}

.info--icon {
  width: 50px;
  padding-bottom: 20px;
}

.info--details {
  font-family: 'Thank You';
  font-weight: bold;
  font-size: 1.5rem;
}

.info--ceremony {
  padding: 0 20px;
}

.directions-link {
  text-transform: uppercase;
  font-weight: bold;
}

.alt-content-title {
  font-family: 'Thank You';
  font-weight: bold;
}

.wp--container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px auto;
}

.wp--img-container {
  width: 150px;
  height: 150px;
  border-radius: 25px;
  overflow: hidden;
}

.wp--img {
  max-width: 100%;
  height: auto;
}

.wp--text {
  width: 50%;
  padding: 0 5px;
}

.wp--text--kids {
  width: 70%;
}

.wp--name {
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
}

.wp--description {
  font-size: 0.75rem;
}

.wp--break {
  width: 150px;
  padding: 10px;
}

.footer {
  background-color: white;
  padding: 15px;
  font-size: 0.75rem;
}

.footer--title {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.35rem;
}

.footer--content {
  display: flex;
  flex-direction: column;
}

.footer--content p {
  margin: 0;
}

.footer--content div {
  width: 80%;
  margin: 0 auto 10px;
}

@media (min-width: 40rem) {
  .show-mob {
    display: none;
  }
  
  .nav-bar--monogram {
    display: block;
  }

  .nav-bar-item {
    width: 100px;
  }

  .mobile-nav-icon--bar {
    display: none;
  }

  .main-nav {
    display: block;
  }

  .banner-text--expanded {
    padding: 15%;
  }

  .banner-text--expanded p {
    font-size: 1.1rem;
  }

  .banner-florals--top {
    width: 300px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .banner-florals--bottom {
    width: 300px;
    position: absolute;
    left: calc(100vw - 350px);
    top: calc(100vh - 350px);
  }

  .content-wrapper {
    width: 50%;
  }

  .content-subtitle {
    font-size: 1rem;
    padding: 0 20px;
  }
  
  .intro--text {
    font-size: 1.2rem;
  }

  .wp--container {
    padding: 10px;
  }

  .wp--text {
    width: 180px;
  }

  .wp--text--kids {
    width: 250px;
  }

  .wp--name {
    font-size: 1.1rem;
  }
  
  .wp--description {
    font-size: 1rem;
  }

  .footer--content {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer--content div {
    margin: 0;
  }

  .footer--credit {
    text-align: left;
  }
  
  .footer--contact {
    max-width: 30%;
    text-align: right;
  }
}

@media (max-height: 40rem) {
  .banner-text--expanded {
    padding: 15%;
  }

  .banner-text--expanded p {
    font-size: 0.85rem;
  }
}
